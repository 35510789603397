import {useEffect, useRef} from 'react';

export const createNotification = (
  message,
  variant = 'error',
  actionButton,
) => {
  return {
    message,
    options: {
      key: new Date().getTime() + Math.random(),
      variant,
      action: actionButton,
    },
  };
};
export const constructPaginated = (url, page) => {
  if (!page) return url;
  if (new RegExp(/\?.+=.*/g).test(url)) return `${url}&page=${page}`;
  return `${url}?page=${page}`;
};

export const constructSortedCategorized = (categorized) => {
  const speakingSkills = [
    'ReadAloud',
    'RepeatSentence',
    'DescribeImage',
    'RetellLecture',
    'AnswerShortQuestion',
  ];
  const writingSkills = ['SummarizeWrittenText', 'WriteEssay'];
  const readingSkills = [
    'ReadingMultipleChoiceSingleAnswer',
    'ReadingMultipleChoiceMultipleAnswer',
    'ReorderParagraph',
    'ReadingFillInTheBlanks',
    'ReadingAndWritingFillInTheBlanks',
  ];
  const listeningSkills = [
    'SummarizeSpokenText',
    'ListeningMultipleChoiceSingleAnswer',
    'ListeningMultipleChoiceMultipleAnswer',
    'ListeningFillInTheBlanks',
    'HighlightCorrectSummary',
    'SelectMissingWord',
    'HighlightIncorrectWords',
    'WriteFromDictation',
  ];
  const sortedCategorized = {...categorized};
  const Speaking = {};
  const Listening = {};
  const Reading = {};
  const Writing = {};
  speakingSkills.forEach((skill) => {
    Speaking[skill] = sortedCategorized.Speaking[skill];
  });
  writingSkills.forEach((skill) => {
    Writing[skill] = sortedCategorized.Writing[skill];
  });
  listeningSkills.forEach((skill) => {
    Listening[skill] = sortedCategorized.Listening[skill];
  });
  readingSkills.forEach((skill) => {
    Reading[skill] = sortedCategorized.Reading[skill];
  });
  return {
    Speaking,
    Writing,
    Reading,
    Listening,
  };
};

export const blob2ObjectUrl = (blobData) => {
  return URL.createObjectURL(blobData);
  // const blobDataInWavFormat = new Blob([blobDataInWebaFormat], {
  //   type: 'audio/wav; codecs=wav',
  // })
  // const dataUrl = URL.createObjectURL(blobDataInWavFormat)
  // return dataUrl
};

export const objectUrl2Blob = async (url) => {
  const r = await fetch(url);
  return r.blob();
};

export const isAi = (questionType) => {
  return (
    [
      'ReadAloud',
      'RepeatSentence',
      'DescribeImage',
      'RetellLecture',
      'AnswerShortQuestion',
      'SummarizeWrittenText',
      'WriteEssay',
      'SummarizeSpokenText',
      'WriteFromDictation',
    ].indexOf(questionType) >= 0
  );
};

export const pascalCaseConvertor = (str) => {
  return str.replace(/\w+/g, (w) => {
    return w[0].toUpperCase() + w.slice(1);
  });
};

export const sortArrayShuffle = (array) => {
  let ctr = array.length;
  let temp = null;
  let index = null;
  while (ctr > 0) {
    index = Math.floor(Math.random() * ctr);
    ctr -= 1;
    temp = array[ctr];
    array[ctr] = array[index];
    array[index] = temp;
  }
  return array;
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const getYoutubeVideoId = (url) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

const getVimeoVideoId = (url) => {
  const regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
  const match = url.match(regExp);
  return match ? match[2] : false;
};

export const parseVideoUrlToIframe = (url) => {
  const youtubeVideoId = getYoutubeVideoId(url);
  if (youtubeVideoId) {
    return `https://www.youtube.com/embed/${youtubeVideoId}`;
  }

  const vimeoVideoId = getVimeoVideoId(url);
  if (vimeoVideoId) {
    return `https://player.vimeo.com/video/${vimeoVideoId}?title=0&byline=0&portrait=0`;
  }

  return url;
};
